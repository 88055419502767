@use "./fonts" as *;

// Colors
$orange: #f35218;
$light-orange: #f7b877;
$bright-orange: #000a14;
$dark-orange: #84231d;
$black: #000a14;
$dark-teal: #071726;
$medium-teal: #081f2e;
$light-teal: #203751;
$bright-teal: #62a3d4;
$gray: #5f6673;
$light-gray: #e4e4ef;
$white: #ffffff;
$cloud: #e3f5ff;
$icon:#758ca3;

// breakpoints and margins
$mobile-margin: 16px;
$mobile-column: auto;

$small-width: 319.9px;

$mobile-width: 320px;

$tablet-width: 767px;
$tablet-margin: 32px;
$tablet-gutter: 24px;
$tablet-column: auto;

$desktop-width: 1280px;
$desktop-margin: 124px;
$desktop-gutter: 24px;
$desktop-column: 63px;

// fonts
$font-regular: "TitilliumWeb";
$font-regular-italics: "TitilliumWeb";

$font-bold: "TitilliumWeb-SemiBold";
$font-bold-italics: "TitilliumWeb-SemiBoldItalics";
