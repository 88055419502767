* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top:0;
}

nav ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  cursor: pointer;
  color: white;
}

main {
  margin-top: 60px;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.section h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.section:nth-child(odd) {
  background-color: #f9f9f9;
}

.section:nth-child(even) {
  background-color: #e9e9e9;
}
